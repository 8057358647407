<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    :deep(#articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2) {
        flex: 1;
    }

    #attachment-container {
        margin: 30px 30px;
    }

    .attachment-element {
        margin: 15px 0px;
        color: #1E73BE;
    }

    .attachment-element:hover {
        cursor: pointer;
    }

    .attachment-element > svg {
        margin-right: 10px;
    }
</style>

<template>
    <Aggrid
        apiRoute="orderDocument/list"
        :apiParams="filter"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :valueFormatterFunctions="valueFormatterFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => editCustomerQuotation(event))"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher une commande client" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']"/>
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayAttachmentsModal" :hideIcon="true" class="auto-height">
        <template v-slot:modalTitle>
            <div>DOCUMENTS</div>
        </template>
        <template v-slot:modalContent>
            <div id="attachment-container">
                <div class="attachment-element" v-for="attachment in attachments" :key="attachment" :data-path="attachment.path" @click="displayDocument">
                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                    {{ attachment.name }}
                </div>
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeAttachmentsModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentOrderId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onOrderUpdated"
    ></OrderDocumentModal>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseSelect from '../../components/Base/BaseSelect'
    import BaseRadioButton from '../../components/Base/BaseRadioButton'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import Modal from '../../components/App/Modal'
    import RadioButtonContainer from '../../components/App/RadioButtonContainer'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import ListQuotationsActionButtonsRenderer from './ListQuotationsActionButtonsRenderer.vue'
    import ListStatusWithIconRenderer from "./ListStatusWithIconRenderer.vue";
    import {formatPrice} from "../../utils/formatter";
    import config from "../../config";
    import OrderDocumentModal  from '../OrderDocument/OrderDocumentModal';
    import axios from 'axios';

    export default {
        name: 'ClientOrders',
        components: {
            BaseButton,
            BaseInput,
            BaseSelect,
            BaseRadioButton,
            RadioButtonContainer,
            ListWithDelete,
            ListQuotationsActionButtonsRenderer,
            Aggrid,
            Modal,
            OrderDocumentModal
        },
        data() {
            return {
                columnDefs: [
                    {field: 'orderDocumentNumber', headerName: `Numéro`, flex: 1, lockPosition: true},
                    {field: 'affairNumber', headerName: `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber'},
                    {field: 'billingName', headerName: `Client`, flex: 1, lockPosition: true},
                    {field: 'label', headerName: `Libellé`, flex: 1, lockPosition: true, wrapText: true},
                    {field: 'amount', headerName : `Montant`, flex: 1, lockPosition: true, valueFormatterFunctionName: 'AmountValueFormatterFunction', filter: 'agNumberColumnFilter' },
                    {headerName: 'Statut', flex: 1, cellRenderer: 'ListStatusWithIconRenderer', lockPosition: true, suppressMenu: true},
                    {headerName: '', width: 220, cellRenderer: 'ListQuotationsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListQuotationsActionButtonsRenderer: ListQuotationsActionButtonsRenderer,
                    ListStatusWithIconRenderer: ListStatusWithIconRenderer
                },
                valueFormatterFunctions: new Map([
                    [
                        'AmountValueFormatterFunction',
                        function(params){
                            return formatPrice(params?.data?.amount);
                        }
                    ]
                ]),
                context: null,
                search: '',
                searchTimeout: null,
                filter: {
                    mode: 'quotation',
                    deleted: false,
                    search: '',
                    validated: true
                },
                displayAttachmentsModal: false,
                attachments: [],
                displayOrderDocumentModal: false,
                orderDocuments: null,
                currentOrderId: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        methods: {
            downloadQuotationInPdf(quotationPath) {
                if (quotationPath !== null) window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${quotationPath}`, '_blank').focus();
            },
            onSearchInputChange(input) {
                if (this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)
            },
            openAttachmentsModal(id, attachments) {
                this.attachments = attachments;
                this.displayAttachmentsModal = true;
            },
            closeAttachmentsModal() {
                this.attachments = [];
                this.displayAttachmentsModal = false;
            },
            displayDocument(event) {
                window.open(`${config.BACK_URL}/${event.currentTarget.dataset.path}`, '_blank').focus();
            },
            onCustomerOrderCreated(orderDocuments) {
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onOrderUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editCustomerQuotation(order) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=quotation&${order.affairId ? `affairId=${order.affairId}` : `orderDocumentId=${order.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentOrderId = order.id;
                    this.onCustomerOrderCreated(result.data);
                })
                .catch(() => {});
            }
        }
    }
</script>