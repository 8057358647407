<style>
    .icon {
        margin-right: 10px;
    }

    .icon-check {
        color: #4FA601;
    }

    .icon-cancel {
        color: #0D3B70;
    }

    .icon-wait {
        color: #78828C;
    }

    .break-word {
        white-space: normal;
    }
</style>

<template>
    <div class="break-word">
        <div v-if="this.mode === 'Refusé'">
            <font-awesome-icon :icon="['fas', 'times']" class="icon icon-cancel"/>
            {{ mode }}
        </div>
        <div v-if="this.mode === 'Validé'">
            <font-awesome-icon :icon="['fas', 'check']" class="icon icon-check"/>
            {{ mode }}
        </div>
        <div v-if="this.mode === 'En attente'">
            <font-awesome-icon :icon="['fas', 'clock']" class="icon icon-wait"/>
            {{ mode }}
        </div>
    </div>
</template>

<script>
  import * as Vue from "vue";
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

  export default {
    name: 'ListStatusWithIconRenderer',
    components: {
      FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
    },
    data() {
      return {
        mode: '',
        icon: '',
        classColor: ''
      }
    },
    beforeMount() {
      switch (this.params.data?.status) {
        case 'refused':
          this.mode = 'Refusé';
          break;
        case 'validated':
          this.mode = 'Validé';
          break;
        default:
          this.mode = 'En attente';
          break;
      }
    }
  }
</script>