<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onEditCustomerQuotation" buttonText="" title="Modifier le devis">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'search']"/>
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadQuotation" buttonText="" title="Télécharger le devis" :disabled="!this.params.data.pdfGenerated">
            <template v-slot:iconBefore>
                DE
            </template>
        </BaseButton>
        <BaseButton @click="onDownloadAttachments" buttonText="" title="Télécharger le PDF" :disabled="this.params.data.attachments.length === 0">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'file-pdf']"/>
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListQuotationsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onEditCustomerQuotation() {
                this.params.context.componentParent.editCustomerQuotation(this.params.data);
            },
            onDownloadQuotation() {
                this.params.context.componentParent.downloadQuotationInPdf(this.params.data.orderDocumentPath);
            },
            onDownloadAttachments() {
                this.params.context.componentParent.openAttachmentsModal(this.params.data.id, this.params.data.attachments);
            }
        }
    }
</script>